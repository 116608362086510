@charset "UTF-8";

/*color*/
$color-theme: #663300;
$color-sub: #f0f0f0;
$color-base: white;
$color-text: #000;
$line: white;

/*font*/
//$theme_fonts: 'Noto Serif', 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$theme_fonts: YuGothic,"ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
// $headline_fonts: 'NotoSans-M' ,YuGothic,"ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$mincho_fonts: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;



@mixin cf{
  &:after{
    content:" ";
    display:block;
    clear:both;
  }
}

/*font size*/
@mixin font-size($size, $base: 16) {
    font-size: $size + px;
    font-size: ($size / $base) * 1rem;
}

/*font関連*/
@mixin title_f{
  font-family: $headline_fonts;
}
@mixin txt_f{
  font-family: $theme_fonts;
}

@mixin flex(){
  display:-webkit-box;
  display:-moz-box;
  display:-ms-box;
  display:-webkit-flexbox;
  display:-moz-flexbox;
  display:-ms-flexbox;
  display:-webkit-flex;
  display:-moz-flex;
  display:-ms-flex;
  display:flex;
  -webkit-box-lines:multiple;
  -moz-box-lines:multiple;
  -webkit-flex-wrap:wrap;
  -moz-flex-wrap:wrap;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
}

img{
  max-width: 100%;
}


html{
  font-size:16px;
  @media screen and (max-width: 768px){
    font-size:12px;
  }
}

body{
  @include font-size(18);
  font-family: $theme_fonts;
  line-height: 1.7;
  color: $color-text;
  margin: 0;
}

p{
  // margin: 0;
}

ul{
  margin: 0;
  padding: 0;
  list-style:none;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}

address{
  font-style: normal;
}


figure{
  line-height: 0;
}

/*汎用型*/
table{
  width: 100%;
  font-size:100%;
  box-sizing: border-box;
  tbody{
    tr{
      th,td{
        padding: 0.8em 0.2em;
        border-bottom: solid 1px #ccc;
        @include font-size(16);
        vertical-align: top;
        // word-break: keep-all;
        @media screen and (max-width: 768px){
          display: block;
        }
        span{
          display: block;
        }
      }
      th{
        @media screen and (max-width: 768px){
          // width: 25%;
        }
      }
      th{
        text-align: left;
      }
      td{
        text-align: right;
        // min-width: 40%;
        @media screen and (min-width: 769px){
          white-space: nowrap;
          padding-left: 30px;
        }
      }
    }
  }
  &.table{
    &__left{
      td{
        text-align: left;
      }
    }
  }
}

a{
  color: $color-text;
  text-decoration: none;
}

figcaption{
  line-height: 1.7;
}

@media screen and (max-width: 768px){
}


/*******************************
  home
*******************************/
.home{
  .main{
    background: url('../../../uploads/main__bg.jpg');
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


/*******************************
  title
*******************************/
.title{
  @include font-size(30);
  &__mincho{
    font-family: $mincho_fonts;
  }
  &__img{
    padding: 0.5em 0;
  }
  &__staff{
    @include font-size(27);
    color: #5f3f1f;
    text-align: left;
    font-weight: normal;
    .small{
      @include font-size(20);
    }
  }
  &__access{
    display: inline-block;
    background-color: rgba(0,0,0,0.65);
    color: #fff;
    padding: 1em 2em;
  }
  &__en{
    font-family: $mincho_fonts;
  }
}

.headline{
  &__min{
    @include font-size(22);
    display: block;
  }
}


/*******************************
  text
*******************************/
.text{
  &-center{
    text-align: center;
  }
  &-right{
    text-align: right;
  }
  &-left{
    text-align: left;
  }
}

.small{
  @include font-size(14);
  font-weight: normal;
}

/*******************************
  padding
*******************************/
.p{
  &b{
    &-6{
      padding-bottom: 4rem!important;
    }
    &-7{
      padding-bottom: 5rem!important;
    }
  }
  &y{
    &-6{
      padding-top: 4rem!important;
      padding-bottom: 4rem!important;
    }
    &-7{
      padding-top: 5rem!important;
      padding-bottom: 5rem!important;
    }
  }
}

/*******************************
  header & nav
*******************************/
#header{
  .main{
    margin-bottom: 0;
  }
}
.home{
  .header{
    margin-bottom: 110px;
  }
}

.nav{
  display: flex;
  align-items: flex-start;
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  right: -220px;
  z-index: 10;
  transition: 0.6s all;
  &__line{
    &_moto{
      background-color: $color-base;
      height: 1px;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    &::after,&::before{
      content:"";
      display: block;
      background-color: $color-base;
      height: 1px;
      // width: 100%;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    &::after{
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    &::before{
      margin-bottom: 0.5em;
    }
  }
  &__btn{
    text-align: center;
    background-color: $color-theme;
    color: $color-base;
    padding: 1em 0.7em;
    width: 80px;
    cursor: pointer;
    span{
      display: block;
      @include font-size(10);
    }
  }
  .globalNav{
    height: 100vh;
    width: 220px;
    background-color: $color-base;
    padding-top: 1em;
    a{
      display: block;
      padding: 0.3em 1em;
      color: $color-text;
      text-decoration: none;
      @include font-size(16);
      &::before{
        content:"\f105";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: 0.5em;
      }
    }
    .calendar{
      a{
        text-indent: -9999px;
        background: url('../../../uploads/nav__calendar.png') no-repeat center center;
        background-size: contain;
        display: block;
        height: 71px;
        margin-top: 20px;
      }
    }
  }
  &__open{
    right: 0;
  }
}


/*******************************
  footer
*******************************/
.footer{
  background-color: #f0f0f0;
  padding-bottom: 0.5em;
  @include font-size(16);
  h3{
    margin-bottom: 0;
  }
  p{
    margin: 0;
  }
  &__Nav{
    margin-bottom: 4em;
    @media screen and (min-width: 769px){
      margin-bottom: 0;
    }
    ul{
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (min-width: 769px){
        display: flex;
        justify-content: space-between;
      }
    }
    li{
      @include font-size(14);
      @media screen and (max-width: 768px){
        &:nth-child(odd){
          background-color: darken($color-sub,5);
        }
      }
      a{
        display: block;
        text-decoration: none;
        color: $color-text;
        padding: 0.5em 1em;
        border-bottom: solid 1px $color-base;
        @media screen and (min-width: 769px){
          padding: 0;
          border: none;
        }
        &::before{
          content:"\f105";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          margin-right: 0.5em;
        }
      }
    }
  }
  .calendar{
    display: none;
  }
}

.sp__btn{
  display: none;
  @media screen and (max-width: 768px){
    display: block;
    position: fixed;
    z-index: 12;
    bottom: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    a{
      display: block;
      background-color: $color-theme;
      color: #fff;
      text-align: center;
      padding: 0.5em;
      @include font-size(20);
    }
    .col-40{
      border-left: solid 1px $color-base;
      &:first-child{
        border: none;
      }
    }
  }
}

/*******************************
  main
*******************************/
.main{
  position: relative;
  margin-bottom: 110px;
  @media screen and (max-width: 768px){
    margin-bottom: 60px;
  }
  // min-height: 50vh;
  &__img{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    @media screen and (max-width: 768px){
      position: static;
    }
    img{
      display: block;
    }
  }
  &__title{
    margin-top: 400px;
    background-color: rgba(255,255,255,0.7);
    text-align: center;
    padding: 1.5em 0.5em 0.9em 0.7em;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 768px){
      margin:0;
      img{
        max-width:80vw;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
}

.submain{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  @media screen and (max-width: 768px){
    position: static;
    img{
      max-width:40vw;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}


/*******************************
  radius
*******************************/
.radius{
  &__img{
    img{
      border-radius: 10px;
    }
  }
}


/*******************************
  single
*******************************/


/*******************************
  form
*******************************/
.mw_wp_form{
  th{
    line-height: 1.2;
  }
  td{
    text-align: left;
  }
  th,td{
    @include font-size(18);
  }
  input[type="text"],
  input[type="email"],
  textarea{
    padding: 0.4em;
    @include font-size(16);
    width: 100%;
  }
}


/*******************************
  vue-infinite-loader
*******************************/
.infinite-loading-container{
  display: block;
  width: 100%;
  margin-top: 100px;
}


/*******************************
  ready-loading
*******************************/
.preloader{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba($color-theme, .95);
  z-index: 30000;
  transition: all 0.5s ease-out;
  opacity: 1;
  &__icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  &__remove{
    left: -100%;
    opacity: 0;
  }
}

/*******************************
  border
*******************************/
.border{
  &-top{
    &-solid{
      border-top: solid 1px #cccccc;
    }
  }
  &-bottom{
    &-solid{
      border-bottom: solid 1px #cccccc;
    }
  }
}



/*******************************
  サイト固有
*******************************/
#Mark{
  background-color: red;
  color: white;
  @include font-size(13);
  display: inline;
  padding: 0.2em 0.5em;
}

.post{
  &-mark{
    display: flex;
    align-items: center;
  }
}

.bnr{
  a{
    color: $color-text;
    text-align: center;
    display: block;
  }
}

.staff{
  th{
    @media screen and (min-width: 769px){
      width: 25%;
    }
  }
  td{
    text-align: left;
    white-space: normal;
  }
  &__nickname{
    text-align: left;
    background-color: rgba(#ffcc00,0.35);
    display: inline-block;
  }
}

.goods{
  position: relative;
  &__text{
    margin: auto 0;
    background-color: rgba(255,255,255,0.7);
    border-radius: 20px;
    max-width:600px;
    height: auto;
    padding: 1.5em 2em;
    @media screen and (min-width: 769px){
      position: absolute;
      max-height:470px;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
}

.access{
  .main{
    margin-bottom: 0;
  }
  &__bg{
    background: url('../../../uploads/access__img02.jpg');
    background-size: cover;
    img{
      display: block;
    }
  }
  &__title-main{
    @include font-size(32);
    line-height: 1.2;
    margin: 0;
  }
  &__title-sub{
    @include font-size(13);
  }
  &__list{
    @include font-size(19);
    li{
      background: url("../../../uploads/icon__scissors.jpg") no-repeat center left;
      padding: 1.5em 0 1.5em 5em;
    }
  }
  &__line{
    border-bottom: solid 5px #cccccc;
  }
  td{
    text-align: left;
  }
}

.info{
  p{
    font-weight: bold;
    border-top: solid 1px #cccccc;
    border-bottom: solid 1px #cccccc;
    padding: 1em 0;
    margin: 0;
  }
}

.required{
  position: relative;
  &::after{
    content:"必須";
    background-color: #999999;
    color: #fff;
    border-radius: 5px;
    @include font-size(13);
    padding: 0.3em 0.4em;
    // position: absolute;
    right: 0;
    line-height: 1.2;
    float: right;
  }
}

.recruit{
  &__text{
    background: url('../../../uploads/recruit__img02.png') no-repeat right top;
    p{
      margin-bottom: 2em;
    }
  }
}

.voice{
  &__title{
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-size(27);
    color: #663300;
    img{
      display: block;
    }
  }
  &__staff{
    &::before{
      content: "社員名：";
      margin-left: 1em;
      margin-right: 1em;
      @include font-size(18);
      color: $color-text;
    }
  }
  &__q{
    @include font-size(21);
    color: #990000;
    border-bottom: solid 1px #990000;
    margin-bottom: 0.8em;
    &::before{
      content:"Q.";
      margin-right: 0.5em;
    }
  }
  &__a{
    @include font-size(16);
  }
}


.archive,.single{
  .main{
    height: 200px;
    @media screen and (min-width: 769px){
      height: 500px;
    }
    background: url('../../../uploads/def__main.jpg');
    background-size: cover;
    position: relative;
    &__title{
      margin-top: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      @include font-size(45);
      line-height: 1;
      padding: 0.5em;
      text-shadow: 0px 0px 3px #fff;
    }
  }
  &__data{
    @include  font-size (14);
    margin: 0.5em 0;
    line-height: 1.2;
    color: $color-theme;
  }
  &__title{
    @include  font-size (18);
  }
  &__content{
    margin-bottom: 100px;
  }
  @media screen and (min-width: 769px){
    &__content{
      order:2;
    }
    &__aside{
      order:1;
    }
  }
  .footer{
    margin-top: 150px;
  }
}

.widget{
  margin-bottom: 3em;
  @include  font-size (16);
  a{
    text-decoration: underline;
  }
  &title{
    @include  font-size (20);
    margin-bottom: 0.5em;
  }
  &_categories{
    ul{
      background-color: #f0f0f0;
      a{
        display: block;
        padding: 0.5em 1em;
        border-bottom: solid 1px #fff;
      }
    }
  }
  &_archive{
    .widgettitle{
      border-top: solid 1px $color-text;
      border-bottom: solid 1px $color-text;
    }
    li{
      &::before{
        content:">> ";
      }
    }
  }
}

@media screen and (max-width: 768px){
  .order-sm-0{
    order:0;
  }
  .order-sm-1{
    order:1;
  }
  .order-sm-2{
    order:2;
  }
  .container{
    img{
      height: inherit;
    }
  }
}